import {
  Badge,
  Button,
  Grid,
  LoadingDots,
  TextLink,
} from "@hexa-ui/components";
import { BarChart2, Download, EyeOn, Plus } from "@hexa-ui/icons";
import { TypeToast } from "admin-portal-shared-services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import CustomTable from "../../components/CustomTable/CustomTable";
import CustomToast from "../../components/CustomToast/CustomToast";
import ExportFileDialog from "../../components/ExportFileDialog/ExportFileDialog";
import CustomPageHeader from "../../components/molecules/CustomPageheader/CustomPageHeader";
import { useExportExperimentQuery } from "../../hooks/queries/experiments/useExportExperimentQuery/useExportExperimentQuery";
import { useGetExperimentListQuery } from "../../hooks/queries/experiments/useGetExperimentListQuery/useGetExperimentListQuery";
import useToastConfig from "../../hooks/useToast/useToastConfig";
import { formatedDate } from "../../services/utils";
import { ExperimentStatusObject } from "../../types/common";
import { ExperimentListTable } from "../../types/experiment";
import { useEnvProvider } from "../../utils/envProvider";
import { useCustomHistory } from "../../utils/routes";
import { SET_TIMEOUT_TOAST_DURATION } from "../ExperimentMutation/utils";
import * as Styles from "./styles";
import { generateJsonFile } from "./utils/utils";

export interface IExperimentRowElement {
  id: string;
  name: string;
  country: string;
  startDate: string;
  endDate: string;
}

export type Pagination = {
  page: number;
  pageSize: number;
  totalElements?: number;
};

const ExperimentsList = (): JSX.Element => {
  const env = useEnvProvider();
  const metricReports = useMemo(
    () => env?.env?.metricReports,
    [env?.env?.metricReports]
  );
  const { Item } = Grid;
  const intl = useIntl();
  const navigate = useCustomHistory();
  const [dataExperiment, setDataExperiment] = useState<ExperimentListTable[]>(
    [] as ExperimentListTable[]
  );

  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    pageSize: 10,
    totalElements: 0,
  });

  const query = useGetExperimentListQuery({
    pageSize: pagination.pageSize,
    page: pagination.page,
  });

  const exportQuery = useExportExperimentQuery();

  useEffect(() => {
    if (exportQuery.isSuccess) {
      generateJsonFile(exportQuery.data, exportQuery.data?.name);
      handleClose();
      handleToast(
        "File successfully exported.",
        true,
        TypeToast.SUCCESS,
        SET_TIMEOUT_TOAST_DURATION
      );
      exportQuery.setExperimentId("");
    }
  }, [exportQuery.isSuccess]);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const { toast, handleToast, handleCloseToast } = useToastConfig();
  const handleClick = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (query.isSuccess) {
      if (!query.data || query.data?.content?.length === 0) {
        setDataExperiment([]);
        return;
      }

      setPagination((prev) => {
        return {
          ...prev,
          totalElements: query.data.pagination.totalElements,
        };
      });
      const { content } = query.data;
      const experiments = content.map((experiment, index) => {
        const status = intl.formatMessage({
          id: `experiment_status.${experiment?.status}`,
        });
        return {
          id: experiment.id,
          name: experiment.name,
          country: intl.formatMessage({
            id: `countries.${experiment?.country}.label`,
          }),
          startDate: experiment.startDate
            ? formatedDate(new Date(experiment?.startDate))
            : "-",
          endDate: experiment.endDate
            ? formatedDate(new Date(experiment?.endDate))
            : "-",
          status: experiment.status && (
            <Badge.Status
              className="mt-2"
              key={experiment?.status as string}
              color={
                (ExperimentStatusObject[experiment?.status as string]
                  ?.color as any) ?? "info"
              }
              label={`● ${intl.formatMessage({ id: status })}`}
            />
          ),
          action: (
            <>
              <Styles.ActionsButton
                key={`MoreHorizontal-${experiment?.id}`}
                onClick={handleClick}
                id={`${experiment?.id}`}
              />
              <Styles.PopoverContainer
                key={`Popover-${experiment?.id}`}
                id={`${experiment?.id}simple-popover`}
                data-testid={`popover-${experiment?.id}`}
                open={open && anchorEl.id === experiment?.id}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                onClose={handleClose}
              >
                <Styles.MenuListCss
                  data-testid={`${experiment?.id}-MenuLists`}
                  key={`MenuList-${experiment?.id}`}
                >
                  <Styles.MenuItemCss
                    key={`go-to-details-${experiment?.id}`}
                    onClick={() => goToDetails(experiment?.id)}
                    data-testid={`go-to-detailss-${experiment?.id}`}
                  >
                    <Styles.MenuItemParagraph>
                      <EyeOn />
                      <span>
                        <FormattedMessage id="experiment_list.action_list.show_details" />
                      </span>
                    </Styles.MenuItemParagraph>
                  </Styles.MenuItemCss>
                  <Styles.MenuItemCss
                    key={`export-${experiment?.id}`}
                    id={`export-${experiment?.id}`}
                    onClick={() => exportQuery.exportJson(experiment?.id)}
                  >
                    <Styles.MenuItemParagraph>
                      <Download />
                      <span>
                        <FormattedMessage id="experiment_list.action_list.export_json_file" />
                      </span>
                    </Styles.MenuItemParagraph>
                  </Styles.MenuItemCss>
                </Styles.MenuListCss>
              </Styles.PopoverContainer>
            </>
          ),
        };
      });
      setDataExperiment(experiments as any);
    }
  }, [query.isSuccess, open, exportQuery.isLoading]);

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.clear();
    };
  }, []);

  const goToDetails = (id: string): void => {
    navigate.goTo(`experiment/${id}`);
  };

  const columns = [
    {
      header: "experiment_list.columns.experiment_name",
      accessor: "name",
      disableSortBy: true,
      width: "auto",
    },
    {
      header: "experiment_list.columns.status",
      accessor: "status",
      disableSortBy: true,
      width: "auto",
    },
    {
      header: "experiment_list.columns.country",
      accessor: "country",
      disableSortBy: true,
      width: "auto",
    },
    {
      header: "experiment_list.columns.start_date",
      accessor: "startDate",
      disableSortBy: true,
      width: "auto",
    },
    {
      header: "experiment_list.columns.estimated_end_date",
      accessor: "endDate",
      disableSortBy: true,
      width: "auto",
    },
    {
      header: "experiment_list.columns.action",
      accessor: "action",
      disableSortBy: true,
      width: "auto",
    },
  ];

  if (query.isLoading) {
    return (
      <div
        data-testid="loading-dots"
        style={{
          display: "flex",
          marginTop: 50,
          justifyContent: "center",
          width: "100%",
        }}
      >
        <LoadingDots />
      </div>
    );
  }
  return (
    <Item
      sm={12}
      md={12}
      lg={12}
      xl={12}
      xs={12}
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "16px",
        paddingBottom: "16px",
        gap: "16px 0",
      }}
    >
      <CustomToast
        dataTestid="custom-toast-dialog"
        isOpened={toast.isOpened}
        duration={toast.duration}
        onClose={handleCloseToast}
        type={toast.type}
        showCloseButton={true}
        message={toast.message}
      />
      <CustomPageHeader
        translatedTitleKey="page_title.experiments_list"
        actions={
          <div style={{ display: "flex" }}>
            <TextLink
              href={`${metricReports.general}`}
              css={{ textDecoration: "none" }}
              target="_blank"
            >
              <Button
                icon={BarChart2}
                data-testid="button-go-to-reports"
                size="medium"
                type="button"
                leading
                className="mr-1"
                variant="secondary"
              >
                <FormattedMessage id="experiment_list.buttons.reports" />
              </Button>
            </TextLink>

            <Button
              icon={Plus}
              data-testid="button-create-experiment"
              size="medium"
              type="button"
              leading
              className="mr-1"
              variant="primary"
              onClick={() => navigate.goTo("new-experiment")}
            >
              <FormattedMessage id="experiment_list.buttons.create_experiment" />
            </Button>
          </div>
        }
      />

      <ExportFileDialog
        open={exportQuery.isLoading}
        title={"exporting_file"}
        messages={["this_may_take_a_few_minutes", "do_not_close_this_window"]}
        close={exportQuery.handleCancelExperimentExportRequest}
      />

      <Item sm={12} md={12} lg={12} xl={12} xs={12}>
        <CustomTable
          search={true}
          columns={columns}
          data={dataExperiment}
          isLoading={query.isLoading}
          onRowClick={(rowData, rowClickedItem) => {
            const isClickOnAction = (
              rowClickedItem?.target as HTMLElement
            ).closest("svg[data-testid='MoreHorizontal']");

            if (!isClickOnAction && !open) {
              goToDetails(rowData?.id);
            }
          }}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Item>
    </Item>
  );
};

export default ExperimentsList;
