import { Countries } from "@/types/experiment";
import { useIntl } from "react-intl";
export const getAvailableCountries = (): Countries => {
  const intl = useIntl();

  return {
    AR: {
      label: intl.formatMessage({ id: "countries.AR.label" }),
      code: intl.formatMessage({ id: "countries.AR.code" }),
    },
    // BO: {
    //   label: intl.formatMessage({ id: "countries.BO.label" }),
    //   code: intl.formatMessage({ id: "countries.BO.code" }),
    // },
    BR: {
      label: intl.formatMessage({ id: "countries.BR.label" }),
      code: intl.formatMessage({ id: "countries.BR.code" }),
    },
    // CA: {
    //   label: intl.formatMessage({ id: "countries.CA.label" }),
    //   code: intl.formatMessage({ id: "countries.CA.code" }),
    // },
    CO: {
      label: intl.formatMessage({ id: "countries.CO.label" }),
      code: intl.formatMessage({ id: "countries.CO.code" }),
    },
    // DO: {
    //   label: intl.formatMessage({ id: "countries.DO.label" }),
    //   code: intl.formatMessage({ id: "countries.DO.code" }),
    // },
    EC: {
      label: intl.formatMessage({ id: "countries.EC.label" }),
      code: intl.formatMessage({ id: "countries.EC.code" }),
    },
    // HN: {
    //   label: intl.formatMessage({ id: "countries.HN.label" }),
    //   code: intl.formatMessage({ id: "countries.HN.code" }),
    // },
    MX: {
      label: intl.formatMessage({ id: "countries.MX.label" }),
      code: intl.formatMessage({ id: "countries.MX.code" }),
    },
    // PA: {
    //   label: intl.formatMessage({ id: "countries.PA.label" }),
    //   code: intl.formatMessage({ id: "countries.PA.code" }),
    // },
    PE: {
      label: intl.formatMessage({ id: "countries.PE.label" }),
      code: intl.formatMessage({ id: "countries.PE.code" }),
    },
    // SV: {
    //   label: intl.formatMessage({ id: "countries.SV.label" }),
    //   code: intl.formatMessage({ id: "countries.SV.code" }),
    // },
    // ZA: {
    //   label: intl.formatMessage({ id: "countries.ZA.label" }),
    //   code: intl.formatMessage({ id: "countries.ZA.code" }),
    // },
    // US: {
    //   label: intl.formatMessage({ id: "countries.US.label" }),
    //   code: intl.formatMessage({ id: "countries.US.code" }),
    // },
  };
};
